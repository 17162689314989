<!-- 支付宝/微信-支付 -->
<template>
  <div class="payalipay">
    <div class="head">
      <div class="lt"><h6>订单提交成功，请尽快完成支付！订单号：{{paydata?paydata.data:''}}</h6></div>
      <div class="rt">应付总额：<span><i>&yen;</i>{{paydata?paydata.data1:'' | formatPrice}}</span></div>
    </div>
    <div class='box'>
      <div class="top">
        <div class="h6">{{paytype&&paytype==1?'微信':"支付宝"}}支付</div>
        <!-- v-show='time!=0&&timeTip&&codeDate' -->
        <div  class="p">距离二维码过期还剩<span>{{time}}</span>秒，过期后请刷新页面重新获取二维码。</div>
        <div v-show='time==0&&!timeTip&&codeDate' class="p p1">二维码已过期，请刷新页面重新获取二维码。</div>
      </div>
      <div class='scan'>
        <div class='lt'>
          <div v-show='loadings' class="loading">
            <svg><use xlink:href="#iconloading"></use></svg>
          </div>
          <div class="img">
            <img :src="paydata?paydata.data2:''">
          </div>
          <div  class="rts">
            <svg><use xlink:href="#iconsaoyisao"></use></svg>
            <div class="btm">
              <p>请使用{{paytype&&paytype==1?'微信':"支付宝"}}扫一扫</p>
              <p>扫描二维码支付</p>
            </div>
          </div>
        </div>
        <div class='rt'>
          <img v-if='!paytype||paytype!=1' src="../../assets/img/payment/alipay.png">
          <img v-if='paytype&&paytype==1' src="../../assets/img/payment/wechat.png">
        </div>
      </div>
      <div class='back'>
        <button @click='backmethod'><svg><use xlink:href="#iconziyuan2"></use></svg>
        选择其他支付方式</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'payalipay',
  mixins: [mixin],//混入
  data(){
    return {
      // paydata:{
      //   data:12312321312,
      //   data1:418,
      //   data2:require('../../assets/img/payment/code.jpg')
      // },//支付数据
      t:0,//计时参数
      time:null,//倒计时时间
      timeTip:true,
      t1:0,//支付轮询
      loadings:true,
    }
  },
  props: {
    paytype: Number,
    paydata:Object,
    codeDate:null
  },
  methods:{
    settime(v){
      var _start = new Date(this.codeDate).getTime();
      // _start
      var _now = Date.now();
      this.t = setTimeout(()=>{
        if((600 - Math.floor((_now - _start)/1000))<=0) {
          this.time=0;
          this.timeTip=false
          clearTimeout(this.t)
          // console.log('倒计时结束操作')
          return
        };
        this.timeTip = true
        // console.log(Math.floor((_now - _start)/1000))
        this.time = 600 - (Math.floor((_now - _start)/1000)<0?0:Math.floor((_now - _start)/1000));
        this.settime();
      },1000)
    },
    backmethod(){
      // 返回
      this.$router.go(-1);
    }
  },
  mounted(){
    this.$nextTick(()=>{
    })
  },
  destroyed(){
    // 组件销毁清空定时变量
    clearInterval(this.t1)
    clearInterval(this.t)
  },
  watch:{
    'codeDate'(n,o){
      this.settime(this.time)
      this.t1 = setInterval(()=>{
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/ExistOrderStatus?orderName='+this._spf(this.$route.query.orderName),this.keys())
          .then(res => {
              if(res.data.Success){
                clearInterval(this.t1)
                if(res.data.Data.state==200){
                  // this.$router.push({path:'payresult',query:{state:1,data:JSON.stringify(res.data.Data)}})
                   this.$router.push({path:'/payresult',query:{ordername:this._spf(this.$route.query.orderName)+'.html'}})
                }
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      },3000)
    },
    'paydata'(n,o){
      this.loadings = false
    }
  }
}
</script>
<style  lang="scss" scoped>
.payalipay {
  .head {
    display: flex;justify-content: space-between;padding:0 20px;margin-top:15px;margin-bottom:20px;
    .lt {
      h6 {
        font-size: 20px;color:#333;
      }
    }
    .rt {
      font-size: 14px;color:#424242;
      span {
        min-width: 130px;font-size: 20px;font-weight: bold;color:#cf3d52;display: inline-block;text-align: right;
        i {
          font-style: normal;margin-right: 2px;font-size: 14px;
        }
      }
    }
  }
  .box {
    background: #f9fafe;padding:20px;border-radius: 10px;
    .top {
      display: flex;justify-content: flex-start;align-items: center;height: 30px;position: relative;
      .h6 {
        font-size: 18px;font-weight: bold;width: 210px;
      }
      .p {
        font-size: 14px;color:#3f4042;position: absolute;left:212px;height: 30px;line-height: 30px;background: #f9fafe;width: 420px;z-index: 8;
        span {
          color:#d62e2e;margin:0 2px;
        }
      }
      .p1 {
        z-index: 9;
      }
    }
    .scan {
      display: flex;justify-content: flex-start;margin-top:15px;
      .lt {
        margin-left:210px;width: 297px;position: relative;
        .img {
          width: 295px;height: 295px;border-top-left-radius: 10px;border-top-right-radius: 10px;overflow: hidden;border:1px solid #ccc;display: flex;justify-content: center;align-items: center;
          img {
            display: block;width: 290px;height: 290px;
          }
        }
        .loading {
           width: 297px;height: 297px;text-align: center;position: absolute;z-index: 9;display: flex;justify-content: center;align-items: center;
          svg {
            width: 80px;height: 80px;animation:turn 1s linear infinite;
          }
          @keyframes turn{
            0%{-webkit-transform:rotate(0deg);}
            25%{-webkit-transform:rotate(90deg);}
            50%{-webkit-transform:rotate(180deg);}
            75%{-webkit-transform:rotate(270deg);}
            100%{-webkit-transform:rotate(360deg);}
          }
        }
        .rts {
          display: flex;justify-content: center;width: 297px;height: 60px;background-image: linear-gradient(to right, #f34e55 , #f34e70);align-items: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;
          svg {
            width:40px;height: 40px;fill: #fff;margin-right: 20px;
          }
          .btm {
            p {
              color:#fff;font-size: 14px;
            }
          }
        }
      }
      .rt {
        img {
          display: block;width: 280px;margin-left:150px;
        }
      }
    }
    .back {
      button {
        margin-top:50px;margin-bottom:10px;color:#366bb7;font-size: 14px;background: none;
        svg {
          width: 10px;height: 10px;fill: #366bb7;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
