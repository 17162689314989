<!-- 付款---收银台 --微信-->
<template>
  <div class="paymentwechat">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='收银台'></logotitle>
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
    <payalipay calss='payalipay'  :codeDate='codeDate' :paydata='paydata'  :paytype='1'></payalipay>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import payalipay from '@/components/payalipay/payalipay.vue';//支付组件
export default {
  name: 'paymentwechat',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'收银台-微微定',
      paydata:null,
      codeDate:null
    }
  },
  components: {
    logotitle,
    payalipay
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Shop/payOrder?PayPassWord=&orderName='+this._spf(this.$route.query.orderName)+'&payType=3',this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              var _obj = {};
              _obj.data = res.data.Data.orderName
              _obj.data1 = res.data.Data.PayAmount
              _obj.data2 = res.data.Data.codeurl
              this.codeDate= res.data.Data.CreateDate
              this.$set(this,'paydata',_obj)
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          // 用户不存在
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.paymentwechat {
  width: 100%;background: #fff;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;padding-bottom:20px;
    .buystep {
      float:right;margin-top:10px;
    }
  }
  .payalipay {
    width: 1200px;margin:0 auto;padding-bottom:30px;
  }
}
</style>
