<!-- 图片加标题组件 -->
<template>
  <div class="logotitle">
    <h6>
      <img :src="this.$store.state.logoUrl1" @click='logoslink'>
      <p>{{title}}</p>
    </h6>
  </div>
</template>
<script>
export default {
  name: 'logotitle',
  props: {
    title: String
  },
  methods:{
    logoslink(){
      // 跳转logourl
      if(this.$store.state.logoUrl){
        window.location.href=this.$store.state.logoUrl
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.logotitle {
  h6 {
    height: 66px;line-height: 66px;float:left;
    img {
      float:left;cursor: pointer;width: 150px;height: 50px;margin-top:10px;
    }
    p {
      float:left;font-size: 24px;font-weight: normal;position: relative;margin-left:40px;color:#2c2c2c;
      &:after {
        content: '';display: block;position: absolute;width: 1px;height: 56px;background: #dadada;top:5px;left:-20px;
      }
    }
  }
}
</style>
